import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Slide from '../components/Slide';

const CurrentOffersSection = styled.section`
  margin: 2rem 0;
  text-align: center;
  h2 {
    font-family: 'Poppins';
    font-size: 3vw;
    color: #2980B9;
    padding: 2rem;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;
const MoreCurrentOffers = () => {
  const offers = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Ftunis.jpg?alt=media&token=0834a002-6328-4233-a966-993a9fa7aa1e",
      details: "Ida e Volta (1 Paragem)  - Abril a Outubro 2025<br>Lisboa a TúnisMilão<br>De: 184€ a 227€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/tun/20250403/20250408/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-tunis-tunisia&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fmilan.jpg?alt=media&token=4fb7c792-223e-4089-a326-d22e8e1a1c84",
      details: "Ida e Volta (Direto)  - Março 2025 a Novembro 2025<br>Porto a Milão<br>De: 38€ a 49€",
      externalLink: "https://www.skyscanner.net/transport/flights/opo/mxp/20250506/20250513/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-porto-para-milao-italia&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fbelo%20horizonte.png?alt=media&token=c2b9d41e-00c2-48f4-b020-e5f8dd104e8a",
      details: "Ida e Volta - Março 2025 e Novembro 2025<br>São Paulo a Belo Horizonte<br>De: De R$ 383",
      externalLink: "https://www.skyscanner.net/transport/flights/gru/cnf/20251030/20251103/?adultsv2=1&cabinclass=economy&childrenv2=&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1&utm_campaign=nova-oferta-de-sao-paulo-para-belo-horizonte-brasil&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fvit%C3%B3ria2.jpg?alt=media&token=d56e455d-9441-493c-bc37-267aea750859",
      details: "Ida e Volta - Março 2025 a Setembro 2025<br>Rio de Janeiro a Vitória<br>De: De R$ 398",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/vix/20250829/20250907/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-vitoria-brasil&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FPunta-Cana-Republica-Dominicana.jpg?alt=media&token=ed734259-2d88-4964-a16b-eb555be96dce",
      details: "Ida e Volta - Janeiro a Setembro 2025<br>Rio de Janeiro a Punta Cana<br>De: R$2055 a R$2369",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/puj/20250324/20250330/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-punta-cana-republica-dominicana&utm_medium=email&utm_source=acumbamail",
    },  
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FMiami.jpg?alt=media&token=693f7cbb-928b-44e6-b870-9c968c500736",
      details: "Ida e Volta - Fevereiro a Outubro 2025<br>Lisboa a Miami<br>De 380€ - 554€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/mia/20250423/20250504/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-miami-estados-unidos&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fjeddah.jpg?alt=media&token=4142c23c-9fbd-433d-9822-06e4de21ff27",
      details: "Ida e Volta - Janeiro a Maio 2025<br>Lisboa a Jeddah<br>De: 193 a 264€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/jed/20250511/20250516/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-jeddah-arabia-saudita&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fbissau.jpg?alt=media&token=9e8801b0-f083-48d9-af31-5561854c408a",
      details: "Ida e Volta - Março a Maio 2025<br>Lisboa a Bissau<br>A partir de 260€",
      externalLink: "https://gr.skyscanner.com/transport/flights/lis/oxb/250322/250401/?adultsv2=1&cabinclass=economy&childrenv2=&currency=eur&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&previousCultureSource=COOKIE&redirectedFrom=www.skyscanner.com&ref=home&rtn=1&utm_campaign=nova-oferta-de-lisboa-para-bissau&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fbogota.jpeg?alt=media&token=74b384b2-5c0a-4088-8b03-e983bbbbc5e9",
      details: "Ida e Volta - Maio 202<br>Rio De Janeiro a Bogotá<br>De: R$1965 a R$2372",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/bog/20250518/20250525/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-bogota-colombia&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FSan_Jose_Costa_Rica.webp?alt=media&token=7fe08ef1-d16c-42b0-8158-702d0e7d508a",
      details: "Ida e Volta - Março 2025 a Outubro 2025<br>Rio De Janeiro a San José, Costa Rica<br>De: R$ 2,025",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/sjo/20250403/20250415/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-san-jose-costa-rica&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fnova%20iorque.webp?alt=media&token=5a96b399-edb6-4085-a955-1d00deae6d6e",
      details: "Ida e Volta (Direto) - Janeiro a Março 2025<br>Lisboa a Nova Iorque<br>396€",
      externalLink: "https://www.skyscanner.ie/transport/flights/lis/nyca/20250118/20250125/?adultsv2=1&airlines=TP&cabinclass=economy_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Froma.webp?alt=media&token=ac83524a-ef19-4600-b603-45c7eb6bf4ab",
      details: "Ida e Volta - Março 2025 a Dezembro 2025<br>São Paulo a Roma<br>De: R$ 3,977",
      externalLink: "https://www.skyscanner.net/transport/flights/gru/fco/20250413/20250504/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-roma-italia&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fbarcelona.jpg?alt=media&token=6221c23e-9f06-4b97-8818-54d9f732f935",
      details: "Ida e Volta - Março 2025 a Outubro 2025<br>Lisboa a Barcelona<br>De: 35€ a 48€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/bcn/20250321/20250402/?adultsv2=1&cabinclass=economy&childrenv2=&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1&utm_campaign=nova-oferta-de-lisboa-para-barcelona-espanha&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fveneza2.webp?alt=media&token=6b13b4e8-0cbd-49b0-b064-ec41151cef18",
      details: "Ida e Volta - Fevereiro e Março 2025<br>São Paulo a Veneza<br>De: R$ 3,982",
      externalLink: "https://gr.skyscanner.com/transport/flights/saoa/veni/250322/250329/?adultsv2=1&cabinclass=economy&childrenv2=&currency=eur&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&previousCultureSource=COOKIE&redirectedFrom=www.skyscanner.com&ref=home&rtn=1&utm_campaign=nova-oferta-de-sao-paulo-para-veneza&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FCancun.jpeg?alt=media&token=b87fbcc6-b916-4a80-bf8c-55c373e0a7d6",
      details: "Ida e Volta - Abril a Agosto 2025<br>São Paulo a Cancún<br>De: R$ 2,364",
      externalLink: "https://www.skyscanner.net/transport/flights/gru/cun/20250728/20250803/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-cancun-mexico&utm_medium=email&utm_source=acumbamail",
    },
  ];

  const handleButtonClick = (offer) => {
    if (offer.externalLink) {
      window.open(offer.externalLink, '_blank');
    } else {
      console.error("No valid externalLink for this offer.");
    }
  };

  return (
    <CurrentOffersSection>
      <h2>Outras Ofertas <Highlight>Premium</Highlight></h2>
      <LazyLoad height={200} offset={100} once>
        <Slide offers={offers} handleButtonClick={handleButtonClick} />
      </LazyLoad>
    </CurrentOffersSection>
  );
};

export default MoreCurrentOffers;