// contentPT.js

const contentPT = {
  title: "Pagamento efetuado! Obrigado!",
  titlebreak: " Vamos finalizar o perfil!",
  subtitle: "Queremos saber mais sobre as tuas preferências de viagem para te enviar ofertas personalizadas!",
  labels: {
    homeAirport: "Escolhe o teu aeroporto de origem",
    airportmin: "Seleciona o teu aeroporto",
    airportmax: "Seleciona apenas um aeroporto",
  },
  messages: {
    profileUpdateError: "Ocorreu um erro ao atualizar o teu perfil:",
    emailConfirmation: "Obrigado! O teu perfil foi atualizado com sucesso. Agora só falta confirmar o email! Enviámos um email com um link que ativa esta conta para começarmos logo a enviar ofertas irresistíveis! Se não o encontrares na caixa de entrada, não te esqueças de verificar a pasta de spam.",
    userNotFound: "Utilizador não encontrado. Por favor, garante que estás a usar o email associado à tua subscrição.",
  },
  submitButton: {
    submitting: "A submeter...",
  },
  budgetRanges: [
    "Viajante Económico",
    "Viajante de Médio Alcance",
    "Viajante de Luxo",
  ],
};

export default contentPT;
