// contentBR.js

const contentBR = {
    button: "Estava brincando!",
    passwordprompt: "A atualização da senha foi cancelada.",
    currentpass: "Por favor, insira sua senha atual:",
    passwordupdated: "Senha atualizada com sucesso!",
    passfail: "Falha ao atualizar a senha. Por favor, tente novamente.",
    cancel: {
      alert1: "Sua conta foi destruída. Puff! Já era!",
      alert2: "Sua assinatura foi alterada para Gratuita.",
    },
    downgrade: {
      alert1: "Sua conta foi destruída. Puff! Já era!",
      alert2: "Sua assinatura foi alterada para Gratuita.",
    },
    auth: "Usuário não autenticado. Por favor, faça login novamente.",
    submitalert: "Perfil atualizado com sucesso!",
    subtitle: "Pode atualizar os seguintes campos do seu perfil",
    span: "Atualiza seu aeroporto de origem",
    budgetrange: [
      "Viajante Econômico",
      "Viajante de Médio Alcance",
      "Viajante de Luxo"
    ],
    airlinevalue: "Selecione uma companhia aérea",
    fail: "Falha ao definir o status de inativo para o usuário. Por favor tente novamente.",
  };
  
  export default contentBR;
    