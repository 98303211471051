import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import styled from 'styled-components';

const HotOffersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin: 2rem 0;
  cursor: pointer;
`;

const Offer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.01);
  
  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const OfferImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OfferImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;

const OfferImageOverlay = styled.div`
  position: absolute;
  bottom: 0; /* Position the text at the bottom */
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  color: white;
  text-align: center;
  border-radius: 0 0 15px 15px; /* Rounded corners for bottom part */
`;

const OfferTitle = styled.h3`
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
`;

const OfferDescription = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  margin-top: 0.2rem;
`;

const HotCurrentOffers = () => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthorized(!!user); // Set true if user exists, false otherwise
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleClick = (offer) => {
    if (isAuthorized) {
      // If logged in, send the user to the external URL
      window.open(offer.link, "_blank");
    } else {
      // If not logged in, navigate to the premium offers page
      navigate('/ofertas-premium');
    }
  };  

  const hotOffers = [
    {
      id: "sal",
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fsal.webp?alt=media&token=3094a80f-1545-427b-95b6-55f62770f8e3",
      title: "Lisboa a Sal, Cabo Verde<br> 119€",
      description: "Ida E Volta (Direto) - Maio 2025 (Limitado)",
      link: "https://www.skyscanner.com/transport/flights/lis/sid/250520/250527/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false¤cy=eur",
    },
    {
      id: "nova iorque",
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fnova%20iorque.webp?alt=media&token=5a96b399-edb6-4085-a955-1d00deae6d6e",  
      title: "Rio de Janeiro a Nova Iorque<br> A partir de: R$2860",
      description: "Ida E Volta - Março a Abril 2025",
      link: "https://gr.skyscanner.com/transport/flights/rioa/nyca/20250331/20250407/?adultsv2=1&cabinclass=economy&childrenv2=&currency=eur&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&previousCultureSource=GEO_LOCATION&redirectedFrom=www.skyscanner.com&ref=home&rtn=1&utm_campaign=nova-oferta-de-sao-paulo-para-valencia&utm_medium=email&utm_source=acumbamail",
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  return (
    <HotOffersContainer>
      {hotOffers.map((offer, index) => (
        <Offer key={index} onClick={() => handleClick(offer)}>
          <OfferImageWrapper>
            <OfferImage src={offer.image} alt={offer.title} />
          </OfferImageWrapper>
          <OfferImageOverlay>
            <OfferTitle dangerouslySetInnerHTML={{ __html: offer.title }} />
            <OfferDescription>{offer.description}</OfferDescription>
          </OfferImageOverlay>
        </Offer>
      ))}
    </HotOffersContainer>
  );
};

export default HotCurrentOffers;
