import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaLongArrowAltRight, FaBars, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import LoginPopup from './LoginPopup';

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  z-index: 90;
  background-color: #EAF0F8;
  padding: 0.2rem 0.2rem;
  box-sizing: border-box;

  @media (min-width: 768px) {
    position: sticky;
    top: 0;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 87.5rem;
  box-sizing: border-box;
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
`;

const HeaderBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;

  @media (min-width: 1025px) {
    flex-wrap: wrap;
  }

  @media (max-width: 1024px) {
    flex-wrap: nowrap;
    padding: 0.7rem 0.7rem; // Add horizontal padding for tablet and mobile
    box-sizing: border-box; // Ensure padding is included in the width
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  order: 1;
  margin-top: 1rem;

  img {
    width: 6rem;
    height: 6rem;
    object-fit: contain;
  }
`;

const HeaderSpacer = styled.div`
  flex: 2;
  order: 2;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  order: 3;
  color: #2980B9;
  font-size: 2rem;

  svg:hover {
    color: #F39C12;
  }

  @media (max-width: 768px) {
    order: 2;
    margin-top: 1rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  order: 1;

  @media (max-width: 768px) {
    flex: 0 1 auto;
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  color: #FF5733;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.p`
  font-family: 'Nunito';
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  color: #0A1111;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    white-space: normal;
    max-width: 150px;
  }
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  order: 2;
  position: relative; // Change from default position

  @media (min-width: 1025px) {
    order: 3;
    flex: 0 1 auto;
    margin-left: auto;
    margin-right: 1.5rem;
  }

  @media (max-width: 1024px) {
    order: 4; // Maintain order for mobile view
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    position: absolute; // Keep absolute to overlay
    top: 100%; // Set to 100% to position below header
    left: 0;
    width: 100%;
    background-color: #EAF0F8;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 100; // Ensure it is on top of other elements
  }
`;

const NavList = styled.ul`
  display: flex;
  gap: 2rem;
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

const NavItem = styled(Link)`
  font-family: 'Nunito';
  font-size: 1.5rem;
  font-weight: 700;
  color: #2980B9;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #F39C12;
  }

  &.active {
    color: #FF5733;
    border-bottom: 2px solid #FF5733;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0.5rem 0;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  order: 3;

  @media (min-width: 1025px) {
    order: 4;
    margin-left: 2rem;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    order: 2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 5rem;
  }

  @media (max-width: 768px) {
    flex: 0 1 auto;
    margin-right: 1rem;
    justify-content: center;
    order: 2;
  }
`;

const LoginButton = styled.button`
  font-family: 'Nunito';
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 0.5rem;
  padding: 0.8rem 1.2rem;
  background: #F39C12;
  color: #0A1111;
  border: none;
  display: flex;
  align-items: center;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: #2980B9;
    color: #F39C12;
  }

  .login-icon {
    margin-left: 0.8rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
  }
`;

const HamburgerMenu = styled.button`
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  color: #333333;
  padding: 0;
  display: none;
  align-items: center;
  order: 4; // Add this line

  @media (max-width: 1024px) {
    display: flex;
    margin-left: 1rem; // Change this from margin-right to margin-left
    order: 3; // Add this line
  }
`;

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const openLoginPopup = () => {
    setLoginPopupOpen(true);
  };

  const closeLoginPopup = () => {
    setLoginPopupOpen(false);
  };

  const handleLoginSuccess = () => {
    setLoginPopupOpen(false);
    navigate('/area-pessoal'); // Navigate to the secure profile page
  };

  return (
    <HeaderContainer>
      <HeaderContent>
        <HeaderTop>
          <LogoContainer>
            {/* Wrap the img element in a Link to redirect to the homepage */}
            <Link to="/">
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FClube%20de%20Voos%20Logo.png?alt=media&token=00ada67a-4721-4ba9-8dd0-9ba89da9d46e" 
                alt="Clube de Voos Logo" 
              />
            </Link>
          </LogoContainer>
          <HeaderSpacer />
          <SocialIcons>
            <a 
              href="https://www.instagram.com/clubedevoos" 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a 
              href="https://www.facebook.com/clubedevoos" 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
        </SocialIcons>
        </HeaderTop>
        <HeaderBottom>
          <TitleContainer>
            <Title>Clube de Voos</Title>
            <Subtitle>Ofertas exclusivas e promoções personalizadas</Subtitle>
          </TitleContainer>
          <NavMenu isOpen={isMobileMenuOpen}>
            <NavList>
              <li><NavItem to="/" className={location.pathname === '/' ? 'active' : ''}>Home</NavItem></li>
              <li><NavItem to="/dicas" className={location.pathname === '/dicas' ? 'active' : ''}>Dicas</NavItem></li>
              <li><NavItem to="/subscreve" className={location.pathname === '/subscreve' ? 'active' : ''}>Subscreve</NavItem></li>
              <li><NavItem to="/faq" className={location.pathname === '/faq' ? 'active' : ''}>Perguntas Frequentes</NavItem></li>
              <li><NavItem to="/sorteio" className={location.pathname === '/giveaway' ? 'active' : ''}>Sorteio e Promoções</NavItem></li>
            </NavList>
          </NavMenu>
          <LoginContainer>
            <LoginButton onClick={openLoginPopup}>
              Premium <FaLongArrowAltRight className="login-icon" />
            </LoginButton>
          </LoginContainer>
          <HamburgerMenu onClick={toggleMobileMenu} aria-label="Menu">
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </HamburgerMenu>
        </HeaderBottom>
      </HeaderContent>
      {isLoginPopupOpen && <LoginPopup isOpen={isLoginPopupOpen} onClose={closeLoginPopup} onLoginSuccess={handleLoginSuccess} />}
    </HeaderContainer>
  );
};

export default Header;