//contentPT
const contentPT = {
  button: "Estava a brincar!",
  passwordprompt: "A atualização da senha foi cancelada.",  
  currentpass: "Por favor, insere a tua senha atual:",
  passwordupdated: "Senha atualizada com sucesso!",
  passfail: "Falha ao atualizar a senha. Por favor, tenta novamente.",
  cancel: {
    alert1: "A tua conta foi destruida. Puff! Foi-se!",
    alert2: "A tua subscrição passou a Gratuita.",
  },
  downgrade: {
    alert1: "Sua conta foi destruída. Puff! Já era!",
    alert2: "Sua assinatura foi alterada para Gratuita.",
  },
  auth: "Utilizador não autenticado. Por favor, faz login novamente.",
  submitalert: "Perfil atualizado com sucesso!",
  subtitle: "Podes atualizar os seguintes campos do teu perfil",
  span: "Atualiza o teu aeroporto de origem",
  budgetrange: [
    "Viajante Económico",
    "Viajante de Médio Alcance",
    "Viajante de Luxo"
  ],
  airlinevalue: "Seleciona uma companhia aérea",
  fail: "Falha ao definir o status de inativo para o utilizador. Por favor tenta novamente.",
};

export default contentPT;
