import React from 'react';
import styled from 'styled-components';
import HotCurrentOffers from '../components/HotCurrentOffers';
import MainCurrentOffers from '../components/MainCurrentOffers';
import MoreCurrentOffers from '../components/MoreCurrentOffers';

const PageContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 1rem;
  }
  
  h2 {
    font-family: 'Poppins';
    font-size: 5vw;
    color: #2980B9;
    padding: 2rem;
  }
`;

const HotOffersSection = styled.div`
  position: relative; /* Enables positioning of the overlay */
  background-color: #FFA500;
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  /* Add overlay */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #EAF0F8;
    opacity: 0.1; /* 30% opacity */
    border-radius: inherit; /* Match the section's rounded corners */
    z-index: 1; /* Ensure it's above the background but below the content */
    pointer-events: none; /* Prevent the overlay from interfering with user interactions */
  }

  /* Ensure content is above the overlay */
  > * {
    position: relative;
    z-index: 2;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;
const PremiumCurrentOffersPage = () => {
  return (
    <PageContainer>
          <h2>Ofertas <Highlight>Quentes</Highlight></h2>
      <HotOffersSection>
        <HotCurrentOffers />
      </HotOffersSection>
      <div>
        <MainCurrentOffers />
      </div>
      <div>
        <MoreCurrentOffers />
      </div>
    </PageContainer>
  );
};

export default PremiumCurrentOffersPage;
