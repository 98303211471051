//contentPT
const contentPT = {
  setstatus: "Um link de redefinição de senha foi enviado para o teu email.",
  setstatus2: "Erro ao enviar o email de redefinição. Verifica o email.",
  seterror: "Por favor, verifica o teu email antes de fazer login.",
  errorm: "Acesso negado. A tua conta não é premium. ",
  error1: "A tua conta não é premium. ",
  error2: "A tua assinatura não está ativa. Se já verificaste o teu email tenta novamente dentro de 5 minutos. ",
  error3: "A tua conta não está verificada.",
  user: "Utilizador não encontrado no banco de dados.",
  usererror: "Utilizador não encontrado.",
  passerror: "Password incorreta.",
  password: "Password:",
  forgot: "Esqueceste a password?",
  }

export default contentPT;