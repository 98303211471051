// src/context/LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('PT'); // Default to PT (European Portuguese)

  useEffect(() => {
    const fetchRegion = async () => {
      try {
        console.log("Fetching user region...");

        const response = await fetch('/getUserRegion');
        const data = await response.json();

        console.log("Fetched Region:", data.region);

        // If the region is Portugal, Greece, or Angola, use PT (European Portuguese)
        if (['PT', 'GR', 'AO'].includes(data.region)) {
          setLanguage('PT'); // Set language to PT for these countries
        } else {
          // For all other regions (including Brazil), set to BR (Brazilian Portuguese)
          setLanguage('BR');
        }

      } catch (error) {
        console.error("Error fetching region:", error);

        // Fallback if region fetch fails, use browser language
        const browserLang = navigator.language || navigator.userLanguage;
        setLanguage(browserLang.includes('pt') ? 'PT' : 'BR');
      }
    };

    fetchRegion();
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;


