import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const CurrentOffersSection = styled.section`
  text-align: center;

  h2 {
    font-family: 'Poppins';
    font-size: 3vw;
    color: #2980B9;
    padding: 2rem;
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 5vw;
    }
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const OffersContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Space out the cards evenly */
  gap: 1rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack offers vertically on mobile */
    width: 100%;
    align-items: center;
    gap: 0.01rem;
  }
`;

const OfferCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px; /* Set card width */
  height: 520px; /* Set card height */
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 1rem;
  position: relative; /* Make the card container relative for positioning the text on top */

  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 768px) {
    width: 300px; /* Set card width */
    height: 420px; /* Set card height */
  }
`;

const OfferImage = styled.img`
  width: 100%;
  height: 100%; /* Ensure the image fills the entire card */
  object-fit: cover;
  border-radius: 15px;
  position: absolute; /* Position the image absolutely inside the card */
  top: 0;
  left: 0;
`;

const OfferTextWrapper = styled.div`
  position: absolute;
  bottom: 0; /* Position the text at the bottom */
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  color: white;
  text-align: center;
  border-radius: 0 0 15px 15px; /* Rounded corners for bottom part */
`;

const OfferTitle = styled.h3`
  font-family: 'Nunito';
  font-size: 1rem;
  font-weight: 600;
  color: white;
  margin: 0;
  text-align: center;
`;

const OfferDescription = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 2rem;
  font-weight: 500;
  color: #ddd;
  text-align: center;
  margin-top: 0.5rem;
`;

const MainCurrentOffers = () => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthorized(!!user); // Set true if user exists, false otherwise
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleClick = (offer) => {
    if (isAuthorized) {
      // Redirect to external URL
      window.open(offer.link, '_blank');
    } else {
      // Redirect to premium offers page
      navigate('/ofertas-premium');
    }
  };

  const offers = [
    {
      id: "madrid",
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FMadrid.webp?alt=media&token=774ff1ed-fc8f-4c23-a646-6effbf247c07",
      destination: "Madrid",
      details: "Ida e Volta - Fevereiro 2025 <br>São Paulo - Madrid (Executiva)<br>R$2787",
      link: "https://www.skyscanner.net/transport/flights/gru/mad/20251102/20251114/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-madrid-espanha&utm_medium=email&utm_source=acumbamail",
    },
    {
      id: "san jose",
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fsan%20jose.jpg?alt=media&token=577ca513-b375-40c3-aad0-7d9f2dee5efa",
      destination: "San José (Costa Rica)",
      details: "Ida e Volta - Março a Outubro 2025<br>São Paulo a San José<br>De: R$1908",
      link: "https://www.skyscanner.net/transport/flights/gru/sju/20250412/20250419/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-san-juan-puerto-rico&utm_medium=email&utm_source=acumbamail&currency=BRL",
    },
    {
      id: "sal",
      image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fsal.webp?alt=media&token=3094a80f-1545-427b-95b6-55f62770f8e3",
      destination: "Sal - Cabo Verde",
      details: "Ida e Volta (Direto) - Fevereiro a Outubro 2025<br>Lisboa a Sal<br>A partir de 158€",
      link: "https://gr.skyscanner.com/transport/flights/lis/sid/250327/250329/?adultsv2=1&cabinclass=economy&childrenv2=&currency=eur&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&previousCultureSource=GEO_LOCATION&redirectedFrom=www.skyscanner.com&ref=home&rtn=1&utm_campaign=nova-oferta-de-lisboa-para-sal&utm_medium=email&utm_source=acumbamail",
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  return (
    <CurrentOffersSection>
      <h2>Ofertas <Highlight>Premium</Highlight></h2>
      <LazyLoad height={800} offset={300} once>
        <OffersContainer>
          {offers.map((offer, index) => (
            <OfferCard key={index} onClick={() => handleClick(offer)}>
              <OfferImage src={offer.image} alt={offer.destination} />
              <OfferTextWrapper>
                <OfferTitle dangerouslySetInnerHTML={{ __html: offer.details }} />
                <OfferDescription>{offer.destination}</OfferDescription>
              </OfferTextWrapper>
            </OfferCard>
          ))}
        </OffersContainer>
      </LazyLoad>
    </CurrentOffersSection>
  );
};

export default MainCurrentOffers;

