import React, { useEffect, useState, useContext } from 'react';
import { getAuth, deleteUser, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'; // Note updatePassword imported
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import styled from 'styled-components';
import contentPT from '../content/secureprofilepage/contentPT';
import contentBR from '../content/secureprofilepage/contentBR';
import LanguageContext from '../context/LanguageContext';
import { signOut } from "firebase/auth";
import HotCurrentOffers from '../components/HotCurrentOffers';

const ProfileContainer = styled.div`
  padding: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  gap: 8rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4rem;
  }
`;


const LeftSection = styled.div`
  flex: 6;
  padding-right: 2rem;
  padding-left: 2rem;

  @media (max-width: 768px) {
    padding-right: 0; 
  }
`;

const RightSection = styled.div`
  flex: 4;
  display: flex;             // Make it a flex container
  flex-direction: column;    // Stack items vertically
  align-items: center;       // Center them horizontally

  h2 {
    font-size: 2vw;
    color: #2980B9;
    text-align: center;}
  
  @media (max-width: 768px) {
    h2 {
    font-size: 6vw;}
  }
`;

const PasswordInput = styled.input`
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #FF5733;
  color: #fff;
  border: none;
  padding: 0.6rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5vw;
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0.05rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #F39C12;
  }
    @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const CancelButton = styled.button`
  background-color: #FF5733;
  color: #fff;
  border: none;
  padding: 0.6rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5vw;
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0.05rem;
  transition: background-color 0.3s;

    &:hover {
    background-color: #F39C12;
  }
    @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const OffersButton = styled.button`
  font-family: 'Nunito';
  font-size: 1.4rem;
  font-weight: 700;
  color: #F39C12;
  background: #2980B9;
  border: none;
  padding: 1.1rem 1.4rem;
  border-radius: 1.5rem;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;

  &:hover {
    background: #F39C12;
    color: #0A1111;
  }

  @media (max-width: 1024px) {
    font-size: 1.2rem;
    padding: 0.9rem 1.2rem;
    margin-top: 1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.7rem 1rem;
    margin-top: 2.5rem;
  }
`;

const Title = styled.h1`
  width: 100%; /* Ensure title takes 100% of the width */
  font-family: 'Poppins', sans-serif;
  font-size: 4vw;
  color: #2980B9;
  text-align: center;
  margin-bottom: 3vw;

  @media (max-width: 768px) {
    font-size: 5vw;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  color: #F39C12;
  text-align: left;
  margin-bottom: 2rem;

    @media (max-width: 768px) {
    font-size: 3.5vw;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ToggleSection = styled.div`
  margin-bottom: 0.1rem;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #2980b9;
  display: flex;
  align-items: center;
`;

const ToggleArrow = styled.span`
  transition: transform 0.3s ease;
  margin-left: 1.2rem;
  ${props => props.isOpen && `
    transform: rotate(180deg);
  `}
`;

const SectionContent = styled.div`
  display: ${props => (props.isVisible ? 'grid' : 'none')};
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Select = styled.select`
  font-size: 1vw;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 2.6vw;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const ModalButton = styled.button`
  margin: 10px;
`;

const Highlight = styled.span`
  color: #FF5733;
`;
  
const SecureProfilePage = () => {
  const {language} = useContext(LanguageContext) || { language: 'BR' };
  const content = language === 'PT' ? contentPT : contentBR;
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({
    name: '',
    airlinePreference: [],
    budgetRanges: [],
    homeAirport: [],
  });
  const [sectionsVisibility, setSectionsVisibility] = useState({
    homeAirport: false,
    budgetRanges: false,
    airlinePreference: false,
  });

  const Modal = ({ onClose, onSelectOption }) => (
    <ModalOverlay>
      <ModalContent>
        <h2>Cancelar Assinatura</h2>
        <ModalButton onClick={() => { onSelectOption('delete'); onClose(); }}>
          Cancelar e excluir todos os dados
        </ModalButton>
        <ModalButton onClick={() => { onSelectOption('downgrade'); onClose(); }}>
          Cancelar Premium e mudar para Grátis
        </ModalButton>
        <ModalButton onClick={onClose}>{content.button}</ModalButton>
      </ModalContent>
    </ModalOverlay>
  );

  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'subscribers', currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } else {
        setUser(null);
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const [isUpdating] = useState(false);

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      alert(content.auth);
      return;
    }
  
    try {
      const currentPassword = prompt(content.currentpass);
  
      if (!currentPassword) {
        alert(content.passwordprompt);
        return;
      }
  
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
  
      // Use updatePassword function from firebase/auth
      await updatePassword(user, newPassword);
  
      alert("Senha atualizada com sucesso!");
      setNewPassword(""); 
    } catch (error) {
      console.error("Erro ao atualizar a senha:", error);
      // Display a generic Portuguese error message instead of error.message
      alert(content.passfail);
    }
  };    
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleCancelSubscription = async (action) => {
    const auth = getAuth();
    const db = getFirestore();
  
    try {
      const currentUser = auth.currentUser;
  
      if (!currentUser) {
        console.error("No authenticated user found.");
        return;
      }
  
      if (action === "delete") {
        // Add the 'deleted: true' flag to the Firestore document
        await updateDoc(doc(db, "subscribers", currentUser.uid), {
          deleted: true,
          deletionTimestamp: serverTimestamp()  
        });
        console.log("User marked as deleted in Firestore.");
  
        // Delete the user from Firebase Authentication
        await deleteUser(currentUser);
        console.log("User deleted from Firebase Authentication.");
  
        // Show the alert for delete
        alert(content.cancel.alert1);
      } else if (action === "downgrade") {
        // Update subscription type to 'free' in Firestore
        await updateDoc(doc(db, "subscribers", currentUser.uid), {
          subscriptionType: "free",
          subscriptionStatus: "Inactive",
        });
        console.log("User subscription downgraded to Free in Firestore.");
  
        // Call backend to set custom claim for 'inactive'
        const response = await fetch("/setInactiveStatus", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid: currentUser.uid }),
        });

        if (!response.ok) {
          throw new Error(content.fail);
        }

        console.log("Custom claims updated to set user as inactive.");

        // Log the user out
        await signOut(auth);
        console.log("User logged out after downgrade.");

        // Alert the user
        alert(content.cancel.alert2);
        }
        } catch (error) {
        console.error("Error processing cancellation:", error);

        // Handle errors for sensitive actions
        if (error.code === "auth/requires-recent-login") {
        alert(content.downgrade.alert1);
        } else {
        alert(content.downgrade.alert2);
        }
        }
        };

  const toggleSectionVisibility = (section) => {
    setSectionsVisibility((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: checked
        ? [...prev[name], value]
        : prev[name].filter(item => item !== value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const db = getFirestore();
    await updateDoc(doc(db, 'subscribers', user.uid), userData);
    alert(content.submitalert);
  };

  if (!user || !userData) {
    return <div>A carregar...</div>;
  }

  return (
    <ProfileContainer>
      <Title>Área Pessoal - {userData.name || user.email}</Title>
      <div style={{ display: 'flex', width: '100%' }}>
      <ColumnsContainer>
        <LeftSection>
          <Subtitle>{content.subtitle}</Subtitle>
          <Form onSubmit={handleSubmit}>

        <ToggleSection onClick={() => toggleSectionVisibility('homeAirport')}>
          <span>{content.span}</span>
          <ToggleArrow isOpen={sectionsVisibility.homeAirport}>⬇️</ToggleArrow>
        </ToggleSection>
        <SectionContent isVisible={sectionsVisibility.homeAirport}>
 		      <Select
            id="homeAirport"
            name="homeAirport"
      value={userData.homeAirport[0] || ''}
            onChange={(e) => setUserData(prev => ({ ...prev, homeAirport: [e.target.value] }))}
          >
            <option value="LISBOA-PT">LISBOA-PT</option>
            <option value="PORTO-PT">PORTO-PT</option>
            <option value="RIO DE JANEIRO-BR">RIO DE JANEIRO-BR</option>
            <option value="SÃO PAULO-BR">SÃO PAULO-BR</option>
          </Select>
        </SectionContent>

        <ToggleSection onClick={() => toggleSectionVisibility('budgetRanges')}>
          <span>Faixa de orçamento</span>
          <ToggleArrow isOpen={sectionsVisibility.budgetRanges}>⬇️</ToggleArrow>
        </ToggleSection>
        <SectionContent isVisible={sectionsVisibility.budgetRanges}>
          {content.budgetrange.map((range, index) => (
            <CheckboxGroup key={index}>
              <input
                type="checkbox"
                id={`budgetRanges-${index}`}
                name="budgetRanges"
                value={range}
                checked={userData.budgetRanges.includes(range)}
                onChange={handleCheckboxChange}
              />
              <label htmlFor={`budgetRanges-${index}`}>{range}</label>
            </CheckboxGroup>
          ))}
        </SectionContent>

        <ToggleSection onClick={() => toggleSectionVisibility('airlinePreference')}>
          <span>Companhia Aérea - Plano de fidelidade</span>
          <ToggleArrow isOpen={sectionsVisibility.airlinePreference}>⬇️</ToggleArrow>
        </ToggleSection>
        <SectionContent isVisible={sectionsVisibility.airlinePreference}>
          <Select
            id="airlinePreference"
            name="airlinePreference"
            value={userData.airlinePreference[0] || ''}
            onChange={(e) => setUserData(prev => ({ ...prev, airlinePreference: [e.target.value] }))}
          >
            <option value="">{content.airlinevalue}</option>
            <option value="Nenhuma">Nenhuma</option>
            <option value="TAP Air Portugal">TAP Air Portugal</option>
            <option value="Ryanair">Ryanair</option>
            <option value="EasyJet">EasyJet</option>
            <option value="Azores Airlines (SATA)">Azores Airlines (SATA)</option>
            <option value="Iberia">Iberia</option>
            <option value="Lufthansa">Lufthansa</option>
            <option value="British Airways">British Airways</option>
            <option value="Air France">Air France</option>
            <option value="KLM">KLM</option>
            <option value="Swiss International Air Lines">Swiss International Air Lines</option>
            <option value="Austrian Airlines">Austrian Airlines</option>
            <option value="Alitalia (ITA Airways)">Alitalia (ITA Airways)</option>
            <option value="Brussels Airlines">Brussels Airlines</option>
            <option value="Scandinavian Airlines (SAS)">Scandinavian Airlines (SAS)</option>
            <option value="Norwegian Air Shuttle">Norwegian Air Shuttle</option>
            <option value="Vueling">Vueling</option>
            <option value="American Airlines">American Airlines</option>
            <option value="Delta Air Lines">Delta Air Lines</option>
            <option value="United Airlines">United Airlines</option>
            <option value="JetBlue Airways">JetBlue Airways</option>
            <option value="Emirates">Emirates</option>
            <option value="Qatar Airways">Qatar Airways</option>
            <option value="Turkish Airlines">Turkish Airlines</option>
            <option value="Ethiopian Airlines">Ethiopian Airlines</option>
            <option value="Air Canada">Air Canada</option>
          </Select>
        </SectionContent>

        <Button type="submit">Atualizar Perfil</Button>
          </Form>
          <Subtitle>Atualizar Senha</Subtitle>
          <form onSubmit={handlePasswordUpdate}>
            <PasswordInput
              type="password"
              placeholder="Nova Senha"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <Button type="submit" disabled={isUpdating}>
              {isUpdating ? "Updating..." : "Update Password"}
            </Button>
          </form>
          <Subtitle>Cancelar Assinatura</Subtitle>
          <CancelButton onClick={() => setIsModalOpen(true)}>Cancelar Assinatura</CancelButton>

          {/* Modal for cancelation options */}
          {isModalOpen && (
            <Modal 
              onClose={() => setIsModalOpen(false)} 
              onSelectOption={handleCancelSubscription} 
            />
          )}
        </LeftSection>
        <RightSection>
        <h2 className="current-offers-title">Ofertas <Highlight>Quentes</Highlight></h2>
        <HotCurrentOffers />
          <OffersButton onClick={() => window.location.href = '/ofertas-premium'}>
            Mais Ofertas Premium
          </OffersButton>
        </RightSection>
        </ColumnsContainer>
      </div>
    </ProfileContainer>
  );
};

export default SecureProfilePage;