import React, { useState, useContext } from 'react';
import { db, auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import contentPT from '../content/subscribePopup/contentPT';
import contentBR from '../content/subscribePopup/contentBR';
import LanguageContext from '../context/LanguageContext';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  font-family: 'Poppins';
  color: #2980B9;
  letter-spacing: 0.01rem;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 400px;
  width: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  &:focus {
    outline: 3px solid #2980b9;
  }
`;

const PremiumLink = styled.a`
  font-family: 'Nunito';
  font-size: 1rem;
  color: #2980B9;
  text-decoration: underline;
  margin-top: 1rem;
`;

const Highlight = styled.span`
  color: #ff5733;
`;

const SubscriptionForm = styled(Form)`
  display: flex;
  flex-direction: column;

  input, select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #ff5733;
    font-family: 'Nunito';
    font-size: 1.2rem;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f39c12;
    }
  }
`;

const ErrorText = styled.div`
  color: #ff0000;
  margin-bottom: 10px;
`;

const SubscribePopup = ({ isOpen, onClose }) => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const [verificationSent, setVerificationSent] = useState(false);

  const departureAirports = [
    "LISBOA-PT",
    "PORTO-PT",
    "RIO DE JANEIRO-BR",
    "SÃO PAULO-BR",
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    homeAirport: Yup.string().required('Required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, values.email, 'temporaryPassword');
      const user = userCredential.user;

      // Save user data to Firestore
      await setDoc(doc(db, 'subscribers', user.uid), {
        name: values.name,
        email: values.email,
        homeAirport: values.homeAirport,
        subscriptionType: 'free',
        isVerified: false,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      // Send verification email
      await sendEmailVerification(user);
      setVerificationSent(true);

      alert(content.alert1);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error details:', error.code, error.message);
      alert(`Ocorreu um erro: ${error.message}`);
    }
    setSubmitting(false);
  };

  if (!isOpen) return null;

  return (
    <PopupOverlay>
      <PopupContent>
        <CloseButton onClick={onClose} aria-label="Close">X</CloseButton>
        <h2>Subscreve ao <Highlight>Clube de Voos</Highlight></h2>
        {verificationSent ? ((content.alert2)
        ) : (
          <Formik
            initialValues={{ name: '', email: '', homeAirport: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <SubscriptionForm>
                <Field type="text" name="name" placeholder="Nome" />
                <ErrorMessage name="name" component={ErrorText} />
                <Field type="email" name="email" placeholder="Email" />
                <ErrorMessage name="email" component={ErrorText} />
                <Field as="select" name="homeAirport">
                  <option value="">{content.airport}</option>
                  {departureAirports.map((airport, index) => (
                    <option key={index} value={airport}>{airport}</option>
                  ))}
                </Field>
                <ErrorMessage name="homeAirport" component={ErrorText} />

                <PremiumLink href={content.link} target="_blank">
                  Adiciona o Meu Aeroporto
                </PremiumLink>

                <button type="submit" disabled={isSubmitting}>Subscreve</button>
              </SubscriptionForm>
            )}
          </Formik>
        )}
        <PremiumLink href="/subscreve#price-table-heading" onClick={onClose}>
          Quero Premium!
        </PremiumLink>
      </PopupContent>
    </PopupOverlay>
  );
};

export default SubscribePopup;
