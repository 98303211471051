import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import contentPT from '../content/faq/contentPT';
import contentBR from '../content/faq/contentBR';
import LanguageContext from '../context/LanguageContext';

const FaqPage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const FaqContainer = styled.div`
  max-width: 900px;
  width: 100%;
`;

const FaqHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const FaqTitle = styled.h1`
  font-family: 'Poppins';
  font-size: 45px;
  font-weight: 500;
  color: #2980b9;

  @media (max-width: 768px) {
    font-size: 35px;
  }
`;

const FaqContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FaqItem = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FaqQuestion = styled.div`
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: 500;
  color: #ff5733;
  cursor: pointer;
  margin-bottom: 10px;
`;

const FaqAnswer = styled.div`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #0a1111;
  display: none;

  &.active {
    display: block;
  }
`;

const Sidebar = styled.div`
  width: 300px;
  padding-left: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 15rem;
  max-width: 100%;
  width: 300px;
  padding-right: 100px;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    width: 100%;
    padding-right: 0;
  }
`;

const SidebarHeading = styled.h2`
  font-family: 'Poppins';
  font-size: 2.2rem;
  font-weight: 500;
  color: #2980b9;
  text-align: center;
  margin-bottom: 20px;

  .highlight {
    color: #ff5733;
  }

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const SubscriptionInput = styled.input`
  width: 80%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Source Sans Pro';
  font-size: 1rem;
`;

const SubscriptionButton = styled.button`
  background-color: #ff5733;
  color: #fff;
  border: none;
  width: 86%;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.6rem;
  font-family: 'Nunito';
  letter-spacing: 0.05rem;

  &:hover {
    background-color: #f39c12;
  }
`;

const StyledSelect = styled.select`
  width: 86%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Source Sans Pro';
  box-sizing: border-box;
`;

const ConfirmationPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 30rem;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #2980B9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 1000;
`;

const PopupContent = styled.div`
  text-align: center;
`;

const PremiumLink = styled.a`
  font-family: 'Nunito';
  font-size: 1rem;
  color: #2980B9;
  text-decoration: underline;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const departureAirports = [
  "LISBOA-PT",
  "PORTO-PT",
  "RIO DE JANEIRO-BR",
  "SÃO PAULO-BR",
];
const FAQ = () => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const [activeIndex, setActiveIndex] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [homeAirport, setHomeAirport] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, 'temporaryPassword');
      const user = userCredential.user;
      await sendEmailVerification(user);

      await setDoc(doc(firestore, 'subscribers', user.uid), {
        name,
        email,
        homeAirport,
        subscriptionType: 'free',
        isVerified: false,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      setShowPopup(true);
      setName('');
      setEmail('');
      setHomeAirport('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert(content.alert);
    }
  };

  return (
    <FaqPage>
      <FaqContainer>
        <FaqHeader>
          <FaqTitle>Perguntas Frequentes</FaqTitle>
        </FaqHeader>
        <FaqContent>
          {content.faqData.map((item, index) => (
            <FaqItem key={index}>
              <FaqQuestion onClick={() => handleToggle(index)}>
                {item.question}
              </FaqQuestion>
              <FaqAnswer 
                className={activeIndex === index ? 'active' : ''}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              />
            </FaqItem>
          ))}
        </FaqContent>
      </FaqContainer>
      <Sidebar>
        <StickyContainer>
          <SidebarHeading>Subscreve ao <br /><span className="highlight">Clube de Voos</span></SidebarHeading>
          <SubscriptionForm onSubmit={handleSubmit}>
          <SubscriptionInput 
                  type="text" 
                  placeholder="Nome" 
                  required 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <SubscriptionInput 
                  type="email" 
                  placeholder="Email" 
                  required 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <StyledSelect
                  value={homeAirport}
                  onChange={(e) => setHomeAirport(e.target.value)}
                  required
                >
                  <option value="">{content.labels.homeAirport}</option>
                  {departureAirports.map((airport, index) => (
                    <option key={index} value={airport}>{airport}</option>
                  ))}
            </StyledSelect>
            {/* Adiciona o Meu Aeroporto link */}
            <PremiumLink href={content.link} target="_blank">
              Adiciona o Meu Aeroporto
            </PremiumLink>
            <SubscriptionButton type="submit">Subscreve</SubscriptionButton>
          </SubscriptionForm>
        </StickyContainer>
      </Sidebar>
      {showPopup && (
      <ConfirmationPopup>
        <CloseButton onClick={() => setShowPopup(false)}>X</CloseButton>
        <PopupContent>
          <h2>{content.popup.heading} <Highlight>Clube de Voos</Highlight></h2>
          <p>{content.popup.text}</p>
          <PremiumLink href="/subscreve#price-table-heading" onClick={() => setShowPopup(false)}>
            Quero Premium!
          </PremiumLink>
        </PopupContent>
      </ConfirmationPopup>
    )}
    </FaqPage>
  );
};

export default FAQ;
