//contentBR
const contentBR = {
    title: "VOA CONOSCO",
    services: "Temos para você",
    herotext: "As melhores promoções de passagens aéreas, direto no seu email",
    herotext2: "Ofertas personalizadas para voar mais e gastar menos",
    ofertasexclusivas: {
      heading: "Ofertas Exclusivas de Voos",
      text: "Receba acesso a ofertas de voos exclusivas na sua caixa de email."
    },
    promoções: {
      heading: "Promoções Personalizadas (Exclusivo para Membros Premium)",
      text: "Promoções de voos personalizadas com base nas preferências de viagens: Destinos; Datas; Acontecimentos."
    },
    acesso: {
      heading: "Acesso Antecipado a Ofertas (Exclusivo para Membros Premium)",
      text: "Receba notificações sobre ofertas de voos antes de serem disponibilizadas aos demais assinantes."
    },
    tarifas: {
      heading: "Tarifas de Erro e Descontos de Última Hora (Premium)",
      text: "Alertas sobre tarifas de erro raras e descontos significativos de última hora."
    },
    opções: {
      heading: "Opções Acessíveis A Todos",
      text: "Aderir ao Clube de Voos é grátis! Assinantes Premium podem aderir por apenas 45€ por ano ou um pagamento único de 99€ para acesso vitalício a todos os benefícios Premium."
    },
  };
  
export default contentBR;
